<script>
	import logoImg from '$lib/assets/logo-rounded.png';

	export let href = '/';
	export let hideText = false;
</script>

<a {href}>
	<img src={logoImg} alt="" />
	{#if !hideText}
		<span class="hide-on-mobile">Client Note Tracker</span>
	{/if}
</a>

<style>
	img {
		width: 38px;
		height: 38px;
		margin-right: 10px;
		box-shadow: 0px 1px 4px 0px rgba(4, 56, 128, 0.1);
		border: 1px solid #d0dcef;
		border-radius: 6px;
	}
	a {
		display: inline-flex;
		align-items: center;
		color: var(--text);
		text-decoration: none;
		margin: 0px;
		font-weight: 600;
		font-size: 1.2rem;
	}
</style>
